import { onServiceWorkerUpdate } from "@3m1/service-worker-updater";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	// <React.StrictMode>
	<App />
	// </React.StrictMode>
);

serviceWorkerRegistration.register({
	onUpdate: onServiceWorkerUpdate,
});
