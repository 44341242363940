import { useEffect } from "react";
import "./App.scss";

function App() {
	useEffect(() => {
		window.location.assign("https://sklep.karbo.eu");
	}, []);

	return (
		<div
			style={{
				height: "100vh",
			}}
			className="flex flex-col justify-center items-center"
		>
			<p>Nowa strona internetowa</p>
			<p>
				<a href="https://sklep.karbo.eu">https://sklep.karbo.eu</a>
			</p>
		</div>
	);
}

export default App;
